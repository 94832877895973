html,
body {
  padding: 0;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  background:#6B4AD9;
  background: #132C51;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.headline-font {
  font-family: "Barlow Semi Condensed",Helvetica,Arial;
  font-weight:700;
}
.subheadline-font {
  font-family: "Source Sans Pro",Helvetica,Arial;
  font-weight:430;
}

.navy-text {
  color: #132C51 !important;
}

.rounded-section {
  border-radius:20px;
  margin-bottom:10px;
}

.section-divider {
  width:100%;
  height:1px;
  border-top:1px solid #EBEBEB;
  margin-top:5px;
  margin-bottom:10px;
}

.rounded-input {
  background:white;
  margin:0px;
  padding:12px 18px;
  border-radius:5px;
  border:1px solid #EBEBEB;
  width:100%;
}

.rounded-input:focus {
  outline:1px solid #29b6f6;
}

.section-left-rail-button {
  padding:10px 20px;
  margin:0px;
  margin-left:10px;
  font-size:18px;
  cursor:pointer;
  width:100%;
  margin-bottom:5px;
}

.section-left-rail-button:hover {
  background:#CEDDE7;
  font-weight:bold;
  color:#132C51;
  border-radius:10px;
}

.section-left-rail-button-selected {
  background:#CEDDE7;
  font-weight:bold;
  color:#132C51;
  border-radius:10px;
}

.table-row-dark {
  color:white;
  padding:0px 10px;
  background:#132C51;
  cursor:pointer;
  margin-bottom:7px;
}

.table-row-dark:hover {
  background:#CEDDE7;
  color:#132C51;
}

.table-row-light {
  color:#132C51;
  padding:0px 10px;
  background:white;
  cursor:pointer;
  margin-bottom:7px;
}

.table-row-light:hover {
  background:#CEDDE7;
  color:#132C51;
}

/* Page Modals */
.page-modal {
  border-radius:10px !important;
  color:white !important;
  background-color: #132C51 !important;
}

.page-modal-right {
  left:unset !important;
  right:20px !important;
  top:20px !important;
}

.page-modal-small {
  max-width:500px !important;
}

.page-modal-smedium {
  max-width:575px !important;
}

.page-modal-medium {
  max-width:700px !important;
  max-height:80vh;
}

.page-modal-large {
  width:55% !important;
  max-height: 80vh !important;
}

.leftrailcount {
  margin-left:7px;
  padding: 1px 10px 1px 9px;
  border-radius:10px;
  font-size:16px;
  vertical-align: text-bottom;
  font-weight: bold;
}


.section-left-rail-button .leftrailcount{
  background-color:transparent;
  color:#CEDDE7;
  border:1px solid #132C51;
  background-color:#132C51;
}

.section-left-rail-button:hover .leftrailcount{
  background-color:#132C51;
  color:#CEDDE7;
  border:1px solid #132C51;
}

.section-left-rail-button-selected .leftrailcount{
  background-color:#132C51;
  color:#CEDDE7;
  border:1px solid #132C51;
}

.section-left-rail-button-selected:hover .leftrailcount{
  background-color:#132C51;
  color:#CEDDE7;
  border:1px solid #132C51;
}

.in-row-select {
  background:transparent;
  border:1px solid white;
  border-radius:5px;
  color:white;
  cursor:pointer;
  font-size:14px;
  width:100px;
  padding:5px;
  height:auto;
  line-height:1;
  margin:0px;
  margin-top:-5px;
}

.in-row-select:hover {
  background:#132C51;
}

.in-row-select-light {
  color:#132C51;
  background:white;
  margin:0px;
  padding:12px 18px;
  border-radius:5px;
  border:1px solid #EBEBEB !important;
  cursor:pointer;
  font-size:14px;
  width:100px;
  padding:5px;
  height:auto;
  line-height:1;
  margin:0px;
  margin-top:-5px;
}

.in-row-select-light:hover {
  border:1px solid #132C51 !important;
}

.in-row-select-light:focus {
  border:1px solid #132C51 !important;
}

#new-message-text:focus{
  border:1px solid #EBEBEB;
}

.table-row-minimize {
  max-height:80px;
  overflow:hidden;
}

@media screen and (max-width: 750px) {
  .page-modal-smedium {
    max-width:100% !important;
    width:92% !important;
  }
  .nav-wrapper {
    height:auto !important;
  }

  
}

@media screen and (max-width: 950px) {
  .page-modal-large {
    max-width:100% !important;
    width:92% !important;
  }

  .page-modal-full {
    max-width:100% !important;
    width:92% !important;
  }

  .page-modal-media {
    max-width:100% !important;
    width:92% !important;
    border:1px solid #EBEBEB;
  }
}

@media screen and (max-height: 400px) {
  .page-modal-medium {
    height:90vh;
    top:4vh;
    max-height:90vh;
  }
}